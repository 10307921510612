import React, { FC } from "react"
import { css } from "@emotion/react"

import * as RD from "@heyhabito/remote-data"

import { breakpoints, colours, TextLink } from "design-kit"

import { useSentry } from "@heyhabito/sentry"

import { useUser } from "../../../../shared-components/Shared/UserProvider"

import { RemortgageMonthlyPayments } from "../../types"
import {
  NoResultsError,
  RemortgageMonthlyPaymentsResponse,
  getRemortgageMonthlyPayments,
} from "../../api"
import { Section } from "../../components/Section"
import { InputCard } from "../../components/InputCard"
import { ContentArea, NextSectionCTA } from "../../components/ContentArea"
import { ReadOnlyAnswers } from "../../components/ReadOnlyAnswers"
import { useCollapseState } from "../../components/CollapsibleSection"
import { NoResultsCard } from "../../components/NoResultsCard"

import { MonthlyPaymentsCard } from "./MonthlyPaymentsCard"
import PaymentsSectionInputs from "./Inputs"
import { useScrollAndFocusUpdates } from "../../utils/focus"
import {
  formatInitialPeriod,
  formatCurrency,
  formatTimePeriod,
} from "../../../../shared-components/Calculators/formatting"

const PaymentsSection: FC<{
  shouldFocusSection: boolean
  existingMonthlyPayments?: RemortgageMonthlyPayments
  onCalculate: (rmp: RemortgageMonthlyPayments) => void
  onContinue?: () => void
}> = ({
  shouldFocusSection,
  existingMonthlyPayments,
  onCalculate,
  onContinue,
}) => {
  const [editable, setEditable] = React.useState<boolean>(
    !existingMonthlyPayments
  )

  const [collapseState, setCollapseState] = useCollapseState(shouldFocusSection)

  const sectionRef = React.useRef<HTMLDivElement>(null)

  const [shouldScroll, setShouldScroll] = React.useState(shouldFocusSection)

  useScrollAndFocusUpdates({
    shouldScrollToSection: shouldScroll && editable,
    sectionRef,
  })

  const [isLtvTooHigh, setIsLtvTooHigh] = React.useState(false)

  const [monthlyPaymentsRequest, setMonthlyPaymentsRequest] = React.useState<
    RD.RemoteData<Error, RemortgageMonthlyPaymentsResponse>
  >(existingMonthlyPayments ? RD.Success(existingMonthlyPayments) : RD.NotAsked)

  const { logToSentry } = useSentry()

  const user = useUser()

  const existingAnswers = existingMonthlyPayments
    ? {
        propertyValue: existingMonthlyPayments.propertyValue,
        remainingBalance: existingMonthlyPayments.borrowingAmount,
        currentMonthlyPayments: existingMonthlyPayments.currentMonthlyPayments,
        initialPeriod: existingMonthlyPayments.initialPeriod,
        mortgageTerm: existingMonthlyPayments.mortgageTerm,
      }
    : undefined

  return (
    <div
      css={css`
        background-color: ${colours.offBlack};

        ${breakpoints.desktop`
          background-color: transparent;
      `}
      `}
    >
      <Section
        label="What you could save on your monthly payments"
        ref={sectionRef}
        tabIndex={-1}
      >
        <InputCard
          css={css`
            background-color: transparent;
          `}
          headingId="habito-mortgage-calculator-remo-payments-input-heading"
          title="What could I save on my monthly payments?"
        >
          {editable ? (
            <PaymentsSectionInputs
              existingAnswers={existingAnswers}
              onCalculate={async answers => {
                const {
                  propertyValue,
                  remainingBalance,
                  initialPeriod,
                  mortgageTerm,
                  currentMonthlyPayments,
                } = answers

                try {
                  const monthlyPaymentsRes = await getRemortgageMonthlyPayments(
                    {
                      borrowingAmount: remainingBalance,
                      propertyValue,
                      initialPeriod,
                      mortgageTerm,
                      currentMonthlyPayments,
                    }
                  )

                  setMonthlyPaymentsRequest(RD.Success(monthlyPaymentsRes))
                  setEditable(false)
                  setShouldScroll(false)

                  if (isLtvTooHigh) {
                    setIsLtvTooHigh(false)
                  }

                  if (!shouldScroll) {
                    setCollapseState("expanded")
                  }

                  onCalculate({
                    ...monthlyPaymentsRes,
                    currentMonthlyPayments,
                    borrowingAmount: remainingBalance,
                    propertyValue,
                    initialPeriod,
                    mortgageTerm,
                  })
                } catch (e) {
                  const err = e instanceof Error ? e : new Error(`${e}`)

                  setIsLtvTooHigh(
                    remainingBalance.value / propertyValue.value > 0.95
                  )

                  setMonthlyPaymentsRequest(RD.Failure(err))

                  // If the user entered values that meant we didn't get any
                  // sourcing results, we don't need to log that
                  if (err instanceof NoResultsError) {
                    return
                  }

                  logToSentry(
                    "Error calculating monthly payments in Remo PaymentsSection",
                    err
                  )
                }
              }}
            />
          ) : (
            <ReadOnlyAnswers
              onEdit={() => {
                setShouldScroll(true)
                setEditable(true)
              }}
              items={[
                {
                  label: "Property price",
                  value: existingMonthlyPayments
                    ? formatCurrency(existingMonthlyPayments.propertyValue)
                    : "",
                },
                {
                  label: "Remaining mortgage balance",
                  value: existingMonthlyPayments
                    ? formatCurrency(existingMonthlyPayments.borrowingAmount)
                    : "",
                },
                {
                  label: "Current monthly payments",
                  value: existingMonthlyPayments
                    ? formatCurrency(
                        existingMonthlyPayments.currentMonthlyPayments
                      )
                    : "",
                },
                {
                  label: "Deal length",
                  value: existingMonthlyPayments
                    ? formatInitialPeriod(existingMonthlyPayments.initialPeriod)
                    : "",
                },
                {
                  label: "Mortgage term",
                  value: existingMonthlyPayments
                    ? formatTimePeriod(existingMonthlyPayments.mortgageTerm)
                    : "",
                },
              ]}
            />
          )}
        </InputCard>

        <ContentArea>
          {RD.isFailure(monthlyPaymentsRequest) ? (
            <NoResultsCard>
              {isLtvTooHigh ? (
                <React.Fragment>
                  <strong>
                    It looks like your loan to value (LTV) is more than 95%
                  </strong>{" "}
                  – we don't have any mortgage deals that match that right now.
                  Try double checking your property value, or{" "}
                  <TextLink
                    href={user ? "/my-properties" : "/sign-up"}
                    text="chat to a Habito expert"
                    colour="black"
                  />{" "}
                  for personalised advice!
                </React.Fragment>
              ) : (
                <React.Fragment>
                  Try changing your mortgage term or how long you would like the
                  deal to last
                </React.Fragment>
              )}
            </NoResultsCard>
          ) : (
            <MonthlyPaymentsCard
              collapseState={collapseState}
              setCollapseState={setCollapseState}
              data={
                RD.isSuccess(monthlyPaymentsRequest) && existingMonthlyPayments
                  ? {
                      equity: monthlyPaymentsRequest.result.equity,
                      potentialMonthlyPayments:
                        monthlyPaymentsRequest.result.potentialMonthlyPayments,
                      savingsOverProductTerm:
                        monthlyPaymentsRequest.result.savingsOverProductTerm,
                      initialPeriod: existingMonthlyPayments.initialPeriod,
                      propertyValue: existingMonthlyPayments.propertyValue,
                      remainingBalance: existingMonthlyPayments.borrowingAmount,
                    }
                  : undefined
              }
            />
          )}

          {onContinue && (
            <NextSectionCTA
              text="See today’s deals"
              onClick={() => {
                onContinue()
              }}
            />
          )}
        </ContentArea>
      </Section>
    </div>
  )
}

export default PaymentsSection
