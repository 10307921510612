import React from "react"
import { css } from "@emotion/react"

import { mixins, typographyStyles, vertical } from "design-kit"

import { useUser } from "../../../../shared-components/Shared/UserProvider"

import { RemortgageMonthlyPaymentsResponse } from "../../api"
import {
  Pounds,
  InitialPeriod,
} from "../../../../shared-components/Calculators/types"
import { ActionBanner } from "../../components/ActionBanner"
import { TIP_TEXT } from "../../components/AnswerQuestionsTip"
import { Card } from "../../../../shared-components/Calculators/Card"
import { GradientSpan } from "../../components/GradientSpan"
import { LoadingBars } from "../../../Shared/LoadingBars"
import { PercentageGraph } from "../../components/PercentageGraph"
import {
  CollapsibleSection,
  CollapseState,
} from "../../components/CollapsibleSection"
import {
  formatCurrency,
  formatInitialPeriod,
} from "../../../../shared-components/Calculators/formatting"

interface Props {
  collapseState: CollapseState
  setCollapseState: (cs: CollapseState) => void
  data?: Data
}

type Data = Pick<
  RemortgageMonthlyPaymentsResponse,
  "equity" | "potentialMonthlyPayments" | "savingsOverProductTerm"
> & {
  initialPeriod: InitialPeriod
  propertyValue: Pounds
  remainingBalance: Pounds
}

const visibleHeading = (
  <span css={css(typographyStyles.headline2)}>
    Your monthly payments could be
  </span>
)

const HEADING_ID = "habito-mortgage-calculator-newmo-monthly-payments-heading"

const Heading: React.VFC<{ data: Data }> = ({
  data: { potentialMonthlyPayments, initialPeriod, savingsOverProductTerm },
}) => (
  <React.Fragment>
    {savingsOverProductTerm.value <= 0 ? (
      <p
        css={css`
          ${typographyStyles.statement4}

          display: block;
          margin-top: ${vertical.xxs};
          margin-bottom: ${vertical.m};
        `}
      >
        Unfortunately we weren’t able to find you a cheaper deal, meaning you’re
        probably best to stay where you are
      </p>
    ) : (
      <p>
        {visibleHeading}{" "}
        <span
          css={css`
            ${typographyStyles.statement4}

            display: block;
            margin-top: ${vertical.xxs};
            margin-bottom: ${vertical.m};
          `}
        >
          <GradientSpan>
            {formatCurrency(potentialMonthlyPayments)}.
          </GradientSpan>{" "}
          {`Over ${formatInitialPeriod(
            initialPeriod,
            true
          )} that would save you ${formatCurrency(savingsOverProductTerm)}.`}
        </span>
      </p>
    )}
  </React.Fragment>
)

export const MonthlyPaymentsCard: React.VFC<Props> = ({
  collapseState,
  setCollapseState,
  data,
}) => {
  const user = useUser()

  const ctaHref = user ? "/my-properties" : "/sign-up"

  return (
    <Card aria-labelledby={HEADING_ID} element="article">
      <h2 css={css(mixins.invisible)} id={HEADING_ID}>
        How much your monthly payments could be
      </h2>

      {!data ? (
        <React.Fragment>
          <p
            css={css`
              margin-bottom: ${vertical.m};
            `}
          >
            {visibleHeading}
          </p>

          <LoadingBars aria-label={TIP_TEXT} widths={["50%"]} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Heading data={data} />

          <CollapsibleSection
            id="remo-monthly-payments"
            collapseState={collapseState}
            setCollapseState={setCollapseState}
            addNegativeBottomMargin
          >
            <ActionBanner
              ctaProps={{
                href: ctaHref,
                text: "Get started",
              }}
              headingId="habito-mortgage-calculator-newmo-monthly-payments-action-banner-heading"
              text="We search the whole market to find you the best remortgage deal possible, and sometimes have access to exclusive deals you wouldn’t be able to get from your current lender."
              title="Get started"
            />

            <PercentageGraph
              equity={data.equity}
              mortgageType="Remortgage"
              propertyValue={data.propertyValue}
              remainingBalance={data.remainingBalance}
            />
          </CollapsibleSection>
        </React.Fragment>
      )}
    </Card>
  )
}
