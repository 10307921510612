import React, { FC } from "react"
import { css } from "@emotion/react"

import { useSentry } from "@heyhabito/sentry"

import { breakpoints, colours } from "design-kit"

import { NewMortgageBorrowingAmounts } from "../../types"
import { getBorrowingAmounts } from "../../api"
import { Section } from "../../components/Section"
import { InputCard } from "../../components/InputCard"
import { ContentArea, NextSectionCTA } from "../../components/ContentArea"
import { ReadOnlyAnswers } from "../../components/ReadOnlyAnswers"
import { useCollapseState } from "../../components/CollapsibleSection"

import {
  computeBorrowingRangesForDisplay,
  YouCouldBorrowCard,
} from "./YouCouldBorrowCard"
import BorrowingSectionInputs from "./Inputs"
import { formatCurrency } from "../../../../shared-components/Calculators/formatting"

const BorrowingSection: FC<{
  shouldScrollToSection: boolean
  existingBorrowingAmounts?: NewMortgageBorrowingAmounts
  onCalculateBorrowingAmounts: (ba: NewMortgageBorrowingAmounts) => void
  onContinue?: () => void
  borrowRef: React.RefObject<HTMLDivElement>
}> = ({
  shouldScrollToSection,
  existingBorrowingAmounts,
  onCalculateBorrowingAmounts,
  onContinue,
  borrowRef,
}) => {
  const [editable, setEditable] = React.useState<boolean>(
    shouldScrollToSection && !existingBorrowingAmounts
  )

  const [collapseState, setCollapseState] = useCollapseState(
    shouldScrollToSection
  )

  const { logToSentry } = useSentry()

  return (
    <div
      css={css`
        background-color: ${colours.offBlack};

        ${breakpoints.desktop`
          background-color: transparent;
      `}
      `}
    >
      <Section label="How much you could borrow" ref={borrowRef} tabIndex={-1}>
        <InputCard
          css={css`
            background-color: transparent;
          `}
          headingId="habito-mortgage-calculator-newmo-input-borrowing-heading"
          title="How much could I borrow?"
        >
          {editable ? (
            <BorrowingSectionInputs
              existingAnswers={existingBorrowingAmounts}
              onCalculate={async answers => {
                const { income, deposit } = answers

                try {
                  const borrowingAmounts = await getBorrowingAmounts({
                    income,
                    deposit,
                  })

                  setEditable(false)

                  if (!shouldScrollToSection) {
                    setCollapseState("expanded")
                  }

                  onCalculateBorrowingAmounts({
                    ...borrowingAmounts,
                    income,
                    deposit,
                  })
                } catch (e) {
                  const err = e instanceof Error ? e : new Error(`${e}`)
                  logToSentry(
                    "Error calculating borrowing amounts in NewMo BorrowingSection",
                    err
                  )
                }
              }}
            />
          ) : (
            <ReadOnlyAnswers
              onEdit={() => setEditable(true)}
              items={[
                {
                  label: "Income",
                  value: existingBorrowingAmounts
                    ? formatCurrency(existingBorrowingAmounts.income)
                    : "",
                },
                {
                  label: "Deposit",
                  value: existingBorrowingAmounts
                    ? formatCurrency(existingBorrowingAmounts.deposit)
                    : "",
                },
              ]}
            />
          )}
        </InputCard>

        <ContentArea>
          <YouCouldBorrowCard
            collapseState={collapseState}
            setCollapseState={setCollapseState}
            data={
              (existingBorrowingAmounts && {
                deposit: existingBorrowingAmounts.deposit,
                borrowingRanges: computeBorrowingRangesForDisplay(
                  existingBorrowingAmounts.ssmipBorrowingAmount,
                  existingBorrowingAmounts.maxBorrowingAmount
                ),
              }) ||
              undefined
            }
          />

          {onContinue && (
            <NextSectionCTA
              text="Next: see what you’ll pay"
              onClick={() => {
                onContinue()
              }}
            />
          )}
        </ContentArea>
      </Section>
    </div>
  )
}

export default BorrowingSection
