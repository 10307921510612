export type RecordEntries<T> = {
  // If T is a Partial<Y>, then, _not_ using Required<T> would sneak in an
  // annoying 'undefined' in the array item type, which is not what we want.
  // e.g for: RecordEntries<Partial<{ a: string, b: number }>>
  // we'd get: (['a', string | undefined] | ['b', number | undefined] | undefined)[]
  // instead of: (['a', string | undefined] | ['b', number | undefined])[]
  [K in keyof Required<T>]: [K, T[K]]
}[keyof T][]

export const recordEntries = <T extends {}>(obj: T): RecordEntries<T> => {
  return Object.entries(obj) as RecordEntries<T>
}

export const recordKeys = <T extends {}>(obj: T) =>
  Object.keys(obj) as Array<keyof typeof obj>
