import React, { FC } from "react"

import { FormSpec, FormProvider } from "@heyhabito/form-utils"
import { useSentry } from "@heyhabito/sentry"

import {
  GBPInputQuestion,
  IntegerInputQuestion,
  PrimaryTwoCTA,
  SelectInput,
} from "design-kit"

import { FormInner } from "../../components/Form"
import {
  validateCurrentMonthlyPayments,
  validateIsPositiveNumber,
  validateMortgageTerm,
  validateRemainingBalance,
} from "../../utils/validation"
import { logUndefinedFields } from "../../utils/forms"
import {
  InitialPeriod,
  Pounds,
  mkPounds,
  Years,
  mkYears,
} from "../../../../shared-components/Calculators/types"
import { initialPeriodSelectOptions } from "../../../../shared-components/Calculators/formatting"

interface PaymentsAnswers {
  propertyValue: string
  remainingBalance: string
  currentMonthlyPayments: string
  initialPeriod: InitialPeriod
  mortgageTerm: string
}

interface ParsedPaymentsAnswers {
  propertyValue: Pounds
  remainingBalance: Pounds
  currentMonthlyPayments: Pounds
  initialPeriod: InitialPeriod
  mortgageTerm: Years
}

const formSpec: FormSpec<PaymentsAnswers> = {
  propertyValue: { toError: validateIsPositiveNumber },
  remainingBalance: { toError: validateIsPositiveNumber },
  currentMonthlyPayments: { toError: validateIsPositiveNumber },
  initialPeriod: {
    toError: () => {
      return null
    },
  },
  mortgageTerm: { toError: validateMortgageTerm },
}

const PaymentsInputCard: FC<{
  existingAnswers?: ParsedPaymentsAnswers
  onCalculate: (ppa: ParsedPaymentsAnswers) => Promise<void>
}> = ({ existingAnswers, onCalculate }) => {
  const { logToSentry } = useSentry()
  const [showErrors, setShowErrors] = React.useState<boolean>(false)

  const [isSubmitting, setIsSubmitting] = React.useState(false)

  /**
   * This error is calculated based on both the remaining mortgage balance and
   * the property price, so it can't be handled by the form library
   */
  const [remainingBalanceError, setRemainingBalanceError] =
    React.useState<string>()

  /**
   * This error is calculated based on both the current monthly payments and the
   * property price, so it can't be handled by the form library
   */
  const [currentMonthlyPaymentsError, setCurrentMonthlyPayments] =
    React.useState<string>()

  return (
    <FormProvider
      formSpec={formSpec}
      showErrors={showErrors}
      previousState={null}
      prepopulationAnswers={{
        propertyValue: existingAnswers?.propertyValue.value.toString(),
        remainingBalance: existingAnswers?.remainingBalance.value.toString(),
        currentMonthlyPayments:
          existingAnswers?.currentMonthlyPayments.value.toString(),
        initialPeriod: existingAnswers?.initialPeriod,
        mortgageTerm: existingAnswers?.mortgageTerm.value.toString(),
      }}
    >
      {(formState, extractAnswers) => (
        <FormInner>
          {formState.propertyValue && (
            <GBPInputQuestion
              title="How much is your home currently worth?"
              description="Start with an estimate if you’re not sure"
              value={formState.propertyValue.value || ""}
              error={formState.propertyValue.error}
              onInput={value => {
                formState.propertyValue?.onChange(value)

                const remainingBalanceValidationResult =
                  formState.remainingBalance
                    ? validateRemainingBalance(
                        formState.remainingBalance.value,
                        value
                      )
                    : null

                if (
                  remainingBalanceValidationResult &&
                  !remainingBalanceError
                ) {
                  setRemainingBalanceError(remainingBalanceValidationResult)
                } else if (
                  !remainingBalanceValidationResult &&
                  remainingBalanceError
                ) {
                  setRemainingBalanceError(undefined)
                }

                const currentMonthlyPaymentsValidationResult =
                  formState.currentMonthlyPayments
                    ? validateCurrentMonthlyPayments(
                        formState.currentMonthlyPayments.value,
                        value
                      )
                    : null

                if (
                  currentMonthlyPaymentsValidationResult &&
                  !currentMonthlyPaymentsError
                ) {
                  setCurrentMonthlyPayments(
                    currentMonthlyPaymentsValidationResult
                  )
                } else if (
                  !currentMonthlyPaymentsValidationResult &&
                  currentMonthlyPaymentsError
                ) {
                  setCurrentMonthlyPayments(undefined)
                }
              }}
            />
          )}

          {formState.remainingBalance && (
            <GBPInputQuestion
              title="What’s your remaining mortgage balance?"
              value={formState.remainingBalance.value || ""}
              error={
                formState.remainingBalance.error ||
                (showErrors ? remainingBalanceError : undefined)
              }
              onInput={value => {
                formState.remainingBalance?.onChange(value)

                const validationError = formState.propertyValue
                  ? validateRemainingBalance(
                      value,
                      formState.propertyValue.value
                    )
                  : null

                if (validationError && !remainingBalanceError) {
                  setRemainingBalanceError(validationError)
                } else if (!validationError && remainingBalanceError) {
                  setRemainingBalanceError(undefined)
                }
              }}
            />
          )}

          {formState.currentMonthlyPayments && (
            <GBPInputQuestion
              title="What’s your current monthly payment?"
              value={formState.currentMonthlyPayments.value || ""}
              error={
                formState.currentMonthlyPayments.error ||
                (showErrors ? currentMonthlyPaymentsError : undefined)
              }
              onInput={value => {
                formState.currentMonthlyPayments?.onChange(value)

                const currentMonthlyPaymentsValidationResult =
                  formState.propertyValue
                    ? validateCurrentMonthlyPayments(
                        value,
                        formState.propertyValue.value
                      )
                    : null

                if (
                  currentMonthlyPaymentsValidationResult &&
                  !currentMonthlyPaymentsError
                ) {
                  setCurrentMonthlyPayments(
                    currentMonthlyPaymentsValidationResult
                  )
                } else if (
                  !currentMonthlyPaymentsValidationResult &&
                  currentMonthlyPaymentsError
                ) {
                  setCurrentMonthlyPayments(undefined)
                }
              }}
            />
          )}

          {formState.initialPeriod && (
            <SelectInput
              title="How long would you like your new deal to last?"
              description="How long your initial interest rate will last. It can be anywhere from 2 years to your full term."
              options={initialPeriodSelectOptions}
              selected={formState.initialPeriod.value}
              onChange={formState.initialPeriod.onChange}
              error={formState.initialPeriod.error}
            />
          )}

          {formState.mortgageTerm && (
            <IntegerInputQuestion
              title="Mortgage term"
              hint={{ kind: "right", text: "years" }}
              value={formState.mortgageTerm.value || ""}
              error={formState.mortgageTerm.error}
              onInput={formState.mortgageTerm.onChange}
            />
          )}

          <PrimaryTwoCTA
            text="Calculate"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={async () => {
              setShowErrors(true)
              const answers = extractAnswers()
              if (
                !answers ||
                remainingBalanceError ||
                currentMonthlyPaymentsError
              )
                return

              const {
                propertyValue,
                remainingBalance,
                currentMonthlyPayments,
                initialPeriod,
                mortgageTerm,
              } = answers

              if (
                propertyValue !== undefined &&
                remainingBalance !== undefined &&
                currentMonthlyPayments !== undefined &&
                initialPeriod !== undefined &&
                mortgageTerm !== undefined
              ) {
                const parsedPropertyValue = parseFloat(propertyValue)
                const parsedRemainingBalance = parseFloat(remainingBalance)
                const parsedCurrentMonthlyPayments = parseFloat(
                  currentMonthlyPayments
                )
                const parsedMortgageTerm = parseInt(mortgageTerm, 10)

                setIsSubmitting(true)

                await onCalculate({
                  propertyValue: mkPounds(parsedPropertyValue),
                  remainingBalance: mkPounds(parsedRemainingBalance),
                  currentMonthlyPayments: mkPounds(
                    parsedCurrentMonthlyPayments
                  ),
                  initialPeriod,
                  mortgageTerm: mkYears(parsedMortgageTerm),
                })

                setIsSubmitting(false)
              } else {
                logUndefinedFields(
                  logToSentry,
                  "Error submitting form in Remo PaymentsSection",
                  {
                    propertyValue,
                    remainingBalance,
                    currentMonthlyPayments,
                    initialPeriod,
                    mortgageTerm,
                  }
                )
              }
            }}
            width="full-width"
          />
        </FormInner>
      )}
    </FormProvider>
  )
}

export default PaymentsInputCard
