import React from "react"
import { css } from "@emotion/react"

import {
  breakpoints,
  HandFingersCrossedIcon,
  HandPeaceIcon,
  horizontal,
  mixins,
  typographyStyles,
  vertical,
  iconSize,
} from "design-kit"

import { useUser } from "../../../../shared-components/Shared/UserProvider"

import {
  addPounds,
  Pounds,
} from "../../../../shared-components/Calculators/types"
import { ActionBanner } from "../../components/ActionBanner"
import { AnswerQuestionsTip } from "../../components/AnswerQuestionsTip"
import { Card } from "../../../../shared-components/Calculators/Card"
import { GradientSpan } from "../../components/GradientSpan"
import { LoadingBars } from "../../../Shared/LoadingBars"
import { PercentageGraph } from "../../components/PercentageGraph"
import {
  CollapsibleSection,
  CollapseState,
} from "../../components/CollapsibleSection"
import { formatCurrency } from "../../../../shared-components/Calculators/formatting"

interface Props {
  collapseState: CollapseState
  setCollapseState: (cs: CollapseState) => void
  data?: {
    deposit: Pounds
    borrowingRanges: BorrowingRanges
  }
}

type BorrowingRanges =
  | { tag: "ssmipRange"; safeBorrowingAmount: Pounds }
  | {
      tag: "ssmipRangeAndStretchRange"
      stretchBorrowingAmount: Pounds
      safeBorrowingAmount: Pounds
    }

export const computeBorrowingRangesForDisplay = (
  ssmipBorrowingAmount: Pounds,
  maxPotentialBorrowingAmount: Pounds
): BorrowingRanges => {
  if (ssmipBorrowingAmount.value > 0.98 * maxPotentialBorrowingAmount.value) {
    return { tag: "ssmipRange", safeBorrowingAmount: ssmipBorrowingAmount }
  } else {
    return {
      tag: "ssmipRangeAndStretchRange",
      stretchBorrowingAmount: maxPotentialBorrowingAmount,
      safeBorrowingAmount: ssmipBorrowingAmount,
    }
  }
}

const visibleHeading = (
  <span css={css(typographyStyles.headline2)}>
    It looks like you could borrow
  </span>
)

const HEADING_ID = "habito-mortgage-calculator-newmo-borrow-heading"

export const YouCouldBorrowCard: React.VFC<Props> = ({
  collapseState,
  setCollapseState,
  data,
}) => {
  const user = useUser()

  const ctaHref = user ? "/mip" : "/sign-up?referrer=mip"
  const borrowingMax =
    data && data.borrowingRanges.tag === "ssmipRangeAndStretchRange"
      ? data.borrowingRanges.stretchBorrowingAmount
      : data?.borrowingRanges.safeBorrowingAmount

  return (
    <Card aria-labelledby={HEADING_ID} element="article">
      <h2 css={css(mixins.invisible)} id={HEADING_ID}>
        How much you can borrow
      </h2>

      {!data ? (
        <React.Fragment>
          <p
            css={css`
              margin-bottom: ${vertical.m};
            `}
          >
            {visibleHeading}
          </p>

          <LoadingBars widths={["90%", "40%"]} />

          <AnswerQuestionsTip text="Answer a couple of questions to find out!" />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>
            {visibleHeading}{" "}
            <span
              css={css`
                ${typographyStyles.statement4}

                display: block;
                margin-top: ${vertical.xxs};
                margin-bottom: ${vertical.m};
              `}
            >
              up to{" "}
              <GradientSpan>
                {formatCurrency(borrowingMax as Pounds)}.
              </GradientSpan>{" "}
              With your deposit that means you could aim for a home worth{" "}
              {formatCurrency(addPounds(borrowingMax as Pounds, data.deposit))}
            </span>
            <span css={css(mixins.invisible)}>.</span>
          </p>

          <CollapsibleSection
            id="newmo-you-could-borrow"
            collapseState={collapseState}
            setCollapseState={setCollapseState}
            addNegativeBottomMargin
          >
            <ActionBanner
              ctaProps={{
                href: ctaHref,
                text: "Get an MIP",
              }}
              headingId="habito-mortgage-calculator-newmo-borrow-action-banner-heading"
              text="Viewing properties? Get a mortgage in principle – it’s an estimate of what you could borrow, on paper, to show estate agents. It’s free!"
              title="Get an MIP"
            />

            <figure>
              <figcaption
                css={css`
                  ${typographyStyles.headline3}
                  margin-top: ${vertical.s};
                `}
              >
                {data.borrowingRanges.tag === "ssmipRange"
                  ? "Your borrowing range"
                  : "Your borrowing ranges"}
              </figcaption>

              <dl
                css={css`
                  margin: ${vertical.s} 0 ${vertical.m};

                  dt {
                    ${typographyStyles.headlineBold}

                    display: flex;

                    :not(:first-of-type) {
                      margin-top: ${vertical.s};
                    }

                    svg {
                      margin-left: ${horizontal.xs};
                      margin-right: ${horizontal.m};

                      ${breakpoints.desktop`
                      margin-left: ${horizontal.s};
                    `}
                    }
                  }

                  dd {
                    ${typographyStyles.body}

                    margin-left: calc(${iconSize.m}px + ${horizontal.xs} + ${horizontal.m});
                    margin-top: calc(${vertical.xxs} / 2);

                    ${breakpoints.desktop`
                    margin-left: calc(${iconSize.m}px + ${horizontal.s} + ${horizontal.m});
                  `}
                  }
                `}
              >
                <dt>
                  <HandPeaceIcon height={iconSize.m} width={iconSize.m} />{" "}
                  {`Up to ${formatCurrency(
                    data.borrowingRanges.safeBorrowingAmount
                  )}`}
                </dt>
                <dd>{`As long as you’ve got a 10% deposit, good credit history, and no big debts or expenses, you should be able to borrow up to ${formatCurrency(
                  data.borrowingRanges.safeBorrowingAmount
                )}, no problem. The live deals you see below are based on this number.`}</dd>

                {data.borrowingRanges.tag === "ssmipRangeAndStretchRange" ? (
                  <React.Fragment>
                    <dt>
                      <HandFingersCrossedIcon
                        height={iconSize.m}
                        width={iconSize.m}
                      />{" "}
                      {`${formatCurrency(
                        data.borrowingRanges.safeBorrowingAmount
                      )} to ${formatCurrency(
                        data.borrowingRanges.stretchBorrowingAmount
                      )}`}
                    </dt>
                    <dd>{`If a lender thinks you’re a great applicant, you might be able to borrow up to ${formatCurrency(
                      data.borrowingRanges.stretchBorrowingAmount
                    )}. That’s four and a half times your income, which is usually the upper limit. This is dependent on the individual lender and your personal circumstances.`}</dd>
                  </React.Fragment>
                ) : null}
              </dl>
            </figure>

            <PercentageGraph
              borrowing={data.borrowingRanges.safeBorrowingAmount}
              deposit={data.deposit}
              mortgageType="NewMortgage"
            />
          </CollapsibleSection>
        </React.Fragment>
      )}
    </Card>
  )
}
